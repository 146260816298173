export const constructFaqJsonSchema = (
  faqs: { question: string; answer: string }[]
): LDJsonSchema => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer
      }
    }))
  }
}

export const constructJsonSchemaFromSeoString = (
  schema: string
): LDJsonSchema | null => {
  const jsonLDSchema: LDJsonSchema | undefined = JSON.parse(schema)
  const faqSchema = jsonLDSchema?.['@graph']
    ?.filter((item: LDJsonSchemaItem) => item['@type'] === 'Question')
    .map(item => {
      // converting wordpress url into peppercontent.io
      const url = (item['@id'] as string).replace(
        process.env.NEXT_PUBLIC_WORDPRESS_URL || '',
        'https://peppercontent.io/blog'
      )
      return { ...item, '@id': url }
    })

  if (faqSchema?.length !== undefined && faqSchema.length === 0) return null

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqSchema
  }
}

export const constructBreadcrumbJsonSchema = (
  items: { name: string; url: string }[]
): LDJsonSchema => {
  const rootUrl = 'https://www.peppercontent.io'

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.name,
      item: `${rootUrl}${item.url}`
    }))
  }
}

type BlogPostSchemaProps = {
  url: string
  title: string
  description: string
  featuredImage: string
  authorName: string
  datePublished: string
  dateModified: string
}
export const constructBlogPostSchema = ({
  url,
  title,
  description,
  featuredImage,
  authorName,
  datePublished,
  dateModified
}: BlogPostSchemaProps): LDJsonSchema => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url
    },
    headline: title,
    description,
    image: featuredImage,
    author: {
      '@type': 'Person',
      name: authorName
    },
    publisher: {
      '@type': 'Organization',
      name: 'Pepper Content',
      logo: {
        '@type': 'ImageObject',
        url: `https://www.peppercontent.io/assets/icons/PepperLogo.svg`
      }
    },
    datePublished,
    dateModified
  }
}
