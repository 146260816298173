import clsx from 'clsx'
import Image from 'next/image'
interface Props {
  title?: string
  className?: string
  mode?: 'light' | 'dark-blue'
  brands?: {
    id?: string | number
    alternativeText: string
    width: SafeNumber
    height: SafeNumber
    url: string
  }[]
}

const defaultBrands = [
  {
    url: '/assets/icons/brands/Facebook.svg',
    width: 128,
    height: 56,
    alternativeText: 'Facebook'
  },
  {
    url: '/assets/icons/brands/Adobe.svg',
    width: 128,
    height: 56,
    alternativeText: 'Adobe'
  },
  {
    url: '/assets/icons/brands/Amazon.svg',
    width: 128,
    height: 56,
    alternativeText: 'Amazon'
  },
  {
    url: '/assets/icons/brands/Indigo.svg',
    width: 128,
    height: 56,
    alt: 'Indigo'
  },
  {
    url: '/assets/icons/brands/Directi.svg',
    width: 128,
    height: 56,
    alternativeText: 'Directi'
  },
  {
    url: '/assets/icons/brands/Adani_black.svg',
    width: 88,
    height: 56,
    alternativeText: 'Adani'
  }
]

function Brands({
  title,
  className,
  mode = 'light',
  brands = []
}: Props): JSX.Element {
  const _brands = brands.length ? brands : defaultBrands
  return (
    <section
      className={clsx(mode == 'light' ? 'bg-light' : 'bg-dark', className)}
    >
      <div className="max-desktop-width mx-auto | pt-6 pb-8">
        {title && (
          <h2
            className={clsx(
              mode === 'light' ? 'text-gray-70' : 'text-white',
              'text-caption text-center uppercase font-medium | mb-4'
            )}
          >
            {title}
          </h2>
        )}

        <div
          className={clsx(
            'flex justify-around flex-wrap gap-4 lg:gap-8 justify-items-center items-center',
            mode === 'light' ? 'invert-0' : 'invert'
          )}
        >
          {_brands.map((brand, index) => {
            return (
              <Image
                key={`brand_image_${index}`}
                src={brand.url}
                className="max-h-14"
                alt={brand.alternativeText ?? ''}
                height={brand.height}
                width={brand.width}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}
export default Brands
