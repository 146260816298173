import React from 'react'

/* blocks */
import Layout from '@/templates/Layout/Layout'
import Button from '@/atoms/Button/Button'
import Brands from '@/templates/Brands/Brands'

/* utils */
import { BUSINESS_WITH_PEPPER } from '@/utils/constants/stats'
import { constructBreadcrumbJsonSchema } from '@/utils/functions/jsonSchema'
import { dataLayerCtaClickEvent } from '@/utils/functions/dataLayerEvents'

const breadcrumbs = [
  {
    name: 'Home',
    url: '/'
  },
  {
    name: 'Login',
    url: '/login/'
  }
]

const LoginPage = (): JSX.Element => {
  return (
    <Layout
      title="Pepper Content - World's Largest Content Marketplace for Businesses and Creators"
      meta={[]}
      jsonSchema={[constructBreadcrumbJsonSchema(breadcrumbs)]}
      canonical={'/login/'}
    >
      <section
        className="flex items-center  flex-col |  my-20 mx-auto"
        style={{ minHeight: '40vh' }}
      >
        <h1 className="h3 font-semibold  |  mb-12">Welcome back!</h1>

        <a
          href="https://business.peppercontent.io"
          rel="noreferrer"
          target="_blank"
          onClick={() =>
            dataLayerCtaClickEvent({
              sectionName: 'Login',
              ctaName: 'Login as a business'
            })
          }
        >
          <Button type="primary" size="large">
            Login as a business
          </Button>
        </a>

        <div className="relative  |  text-center |  w-full  |  my-5">
          <span
            className="mx-auto"
            style={{
              borderBottom: '1px solid black',
              display: 'block',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 10,
              width: 200
            }}
          />
          <span
            className="bg-white  |  inline-block  |  relative  |  px-4"
            style={{ zIndex: 1 }}
          >
            OR
          </span>
        </div>

        <a
          href="https://creators.peppercontent.io"
          rel="noreferrer"
          target="_blank"
          onClick={() =>
            dataLayerCtaClickEvent({
              sectionName: 'Login',
              ctaName: 'Login as a creator'
            })
          }
        >
          <Button size="large" type="secondary">
            Login as a creator
          </Button>
        </a>
      </section>
      <Brands
        title={`${BUSINESS_WITH_PEPPER.display} Businesses trust us with their content`}
      />
    </Layout>
  )
}

export default LoginPage
