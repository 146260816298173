export const CONTENT_CREATORS = {
  value: 120_000,
  display: '1,20,000+',
  alt: '1.2L+'
}

export const CONTENT_TYPES = {
  value: 60,
  display: '60+'
}

export const INDUSTRIES = {
  value: 100,
  display: '100+'
}

export const CONTENT_GENRES = {
  value: 150,
  display: '150+'
}

export const BUSINESS_WITH_PEPPER = {
  value: 2500,
  display: '2500+'
}

export const CONTENT_PIECES_DELIVERED = {
  value: 10_00_000,
  display: '1Mn+'
}

export const PROJECTS_DELIVERED = {
  value: 200_000,
  display: '200K+'
}

export const CREATOR_EARNINGS = {
  value: 35_00_000,
  display: '$3.5M+'
}

export const PEPPER_TEAM_COUNT = {
  value: 100,
  display: '100+'
}

export const LANGUAGES = {
  value: 45,
  display: '45+'
}
